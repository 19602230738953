import React from "react";
import DashboardHeader from "./header";
import DashboardFooter from './footer';

/* Toast Notification Import */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DashboardLayout = ({ children }) => {
    return (
      <main>
        <DashboardHeader />
        <div className="main">
            {children}
        </div>
        <ToastContainer />
        <DashboardFooter />
      </main>
    );
};

export default DashboardLayout;