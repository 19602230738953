const actions = {
  SET_USER: "SET_USER",
  SET_TOKEN: "SET_TOKEN",
  SET_LOGGEDIN: "SET_LOGGEDIN",
  SET_LOADING: "SET_LOADING",
  setUser: (data) => {
    return { type: actions.SET_USER, data };
  },
  setToken: (data) => {
    return { type: actions.SET_TOKEN, data };
  },
  setLoggedin: (data) => {
    return { type: actions.SET_LOGGEDIN, data };
  },
  setLoading: (data) => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },
};

export default actions;
