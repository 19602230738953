/* eslint-disable no-unused-vars */
import axios from "axios";
import { EXTERNAL_SERVER_URL } from "../config/index";

const request = axios.create({
  baseURL: EXTERNAL_SERVER_URL,
  timeout: 1000000,
});
let requests = [];
request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log("Error: ", error);
    // do something with request error
    return Promise.reject(error);
  }
);
request.interceptors.response.use(
  function (response) {
    // store.dispatch(actions.setLoading(false));
    // Do something with response data

    return response;
  },
  function (error) {
    // store.dispatch(actions.setLoading(false));

    // Do something with response error
    return Promise.reject(error);
  }
);

export default request;
