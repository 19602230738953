/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import sampleData from "./sampleData";
import Select from "react-select";

/* ICON IMPORTS */
import ImportIcon from "@iconscout/react-unicons/icons/uil-import";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-user-times";
import EyeIcon from "@iconscout/react-unicons/icons/uil-eye";

/* Form Control */
import { useFormik } from "formik";
import * as yup from "yup";

/* Toast Notification Import */
import { ToastContainer, toast } from "react-toastify";

/* Moment import for Date Customization  */
import moment from "moment";

/* API IMPORTS */
import {
  getAutomations,
  updateUser,
  addUser,
  deleteUser,
  getAllAutomationList,
  getUsersList,
} from "../../../service/api";
import { SET_FORMAT } from "../../../config";

/* Validation Schema  */
const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  mobile: yup.string().required("Phone Number is Required"),
  country: yup.string().required("Country is Required"),
  address: yup.string().required("Address is Required"),
  city: yup.string().required("City is Requried"),
  state: yup.string().required("State is Required"),
  zipCode: yup.string().required("Zip-Code is Required"),
  tradingExp: yup.string().required("Trading Experience is Required"),
  optionTradingExp: yup
    .string()
    .required("Other Trading Experience is Required"),
  hearAboutUs: yup.string().required("Hear About Us is Required"),
  twoFactorAuthentication: yup
    .boolean()
    .required("2 Factor Authentication is Required"),
});

const findUpper = (string1, string2) => {
  function extractFirstLetter(string) {
    let extractedString = [];

    for (var i = 0; i < string?.length; i++) {
      if (
        (string?.charAt(i) === string?.charAt(i).toUpperCase() ||
          string?.charAt(i) === string?.charAt(i).toLowerCase()) &&
        string?.charAt(i) !== " "
      ) {
        extractedString.push(string?.charAt(i).toUpperCase());
      }
    }
    if (extractedString.length > 1) {
      return extractedString[0];
    } else {
      return extractedString[0];
    }
  }
  let letterOne = extractFirstLetter(string1);
  let letterTwo = extractFirstLetter(string2);
  if (letterOne !== undefined && letterTwo !== undefined) {
    return letterOne + letterTwo;
  } else if (letterOne === undefined && letterTwo !== undefined) {
    return letterTwo;
  } else if (letterOne !== undefined && letterTwo === undefined) {
    return letterOne;
  } else {
    return "";
  }
};
const Automations = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [search, setSearch] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);

  const [userIds, setUserIds] = useState([]);
  const [userDetailList, setUserDetailList] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState(null);

  console.log("userDetailList", userDetailList);

  const handlePageChange = async (e) => {
    setPage(e);
    getUserData();
  };
  const handleSizePerPageChange = async (e) => {
    setSizePerPage(e);
    getUserData();
  };

  const columns = [
    {
      name: "User",
      /* grow: "2", */
      selector: (row) => (
        <div className="user-detail">
          {row?.firstName ? (
            <div className="avatar">
              {findUpper(row?.firstName, row?.lastName)}
            </div>
          ) : (
            <p>No User Found</p>
          )}
          <h5>
            {row?.firstName} {row?.lastName}
          </h5>
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => (
        <span className="light-text">
          {" "}
          {row?.email ? row?.email : "No User Found"}
        </span>
      ),
    },
    {
      name: "Underlying",
      grow: 0.5,
      selector: (row) => <span className="light-text"> {row?.underlying}</span>,
    },
    {
      name: "Amount",
      width: "150px",
      selector: (row) => (
        <span className="light-text">{SET_FORMAT(row?.amount)}</span>
      ),
    },
    {
      name: "Frequency",
      grow: 0.5,
      selector: (row) => (
        <p className="text-base light-text text-capitalize">{row?.frequency}</p>
      ),
    },
    {
      name: "Schedule",
      grow: 1,
      selector: (row) => <span className="light-text"> {row.schedule}</span>,
    },
    {
      name: "Status",
      grow: 0.5,
      selector: (row) => <span className="light-text">{row.status}</span>,
    },
  ];

  const getUserData = () => {
    setLoading(true);
    getAllAutomationList()
      .then((res) => {
        console.log("res", res?.data?.allocations);
        setFetchedData(res?.data?.allocations);
        setFilteredData(res?.data?.allocations);
        setTotalDocs(res?.data?.allocations?.length);
        const uniqueUserIds = Array.from(
          new Set(res?.data?.allocations?.map((item) => item.userId))
        );
        setUserIds(uniqueUserIds);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUserGet = () => {
    if (userIds?.length > 0) {
      getUsersList({ userIds: userIds })
        .then((res) => {
          console.log("USER", res);
          setUserDetailList(res?.data);
          // toast.success(res?.message);
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {});
    }
  };

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="pagination-loading-screen">
        <p>Please wait!</p>
        <p>We are fetching data </p>
      </div>
    );
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    handleUserGet();
  }, [userIds]);

  useEffect(() => {
    if (userDetailList) {
      let tempArray = [];
      fetchedData?.forEach((element) => {
        tempArray.push({
          firstName: userDetailList?.find((el) => el?._id === element?.userId)
            ?.firstName
            ? userDetailList?.find((el) => el?._id === element?.userId)
                ?.firstName
            : null,
          lastName: userDetailList?.find((el) => el?._id === element?.userId)
            ?.lastName
            ? userDetailList?.find((el) => el?._id === element?.userId)
                ?.lastName
            : null,
          email: userDetailList?.find((el) => el?._id === element?.userId)
            ?.email
            ? userDetailList?.find((el) => el?._id === element?.userId)?.email
            : "No User Found",
          underlying: element?.underlying,
          amount: element?.amount,
          frequency: element?.strategy?.split("-")?.[0],
          schedule: element.strategy
            ? (moment(
                new Date().setDate(
                  parseFloat(element?.strategy?.split("-")?.[1])
                )
              ).format("DD") > moment(new Date()).format("DD")
                ? moment().format("MMMM")
                : moment().add(1, "months").format("MMMM")) +
              " " +
              element?.strategy?.split("-")?.[1]
            : "-",
          status: element.active ? "Active" : "Paused",
        });
      });
      setFetchedData(tempArray);
      setFilteredData(tempArray);
    }
  }, [userDetailList]);

  useEffect(() => {
    if (search) {
      // fetchedData?.filter((el) => new RegExp(search, "i").test(el?.firstName) || new RegExp(search, "i").test(el?.lastName));
      setFilteredData(
        fetchedData?.filter(
          (el) =>
            new RegExp(search, "i").test(el?.firstName) ||
            new RegExp(search, "i").test(el?.lastName)
        )
      );
    } else {
      setFilteredData(fetchedData);
    }
  }, [search]);

  useEffect(() => {
    if (selectedStatus) {
      setFilteredData(
        fetchedData?.filter((el) => el?.status === selectedStatus)
      );
    } else {
      setFilteredData(fetchedData);
    }
  }, [selectedStatus]);
  return (
    <section className="users">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Automations List</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your company's total automations list
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-none resp-start">
                <Button className="import-btn">
                  <ImportIcon size="14" color="#323232" className="btn-icon" />
                  Import
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        {/* USERS LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <div className="left-header">
                <h2>Total automations on ScaleTrade: {totalDocs}</h2>
              </div>
              <div className="right-header d-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                  }}
                  value={search}
                />
                <Select
                  onChange={(e) => {
                    setSelectedStatus(e?.value);
                  }}
                  value={[
                    { label: "All", value: null },
                    { label: "Active", value: "Active" },
                    { label: "Paused", value: "Paused" },
                  ]?.find((el) => el?.value === selectedStatus)}
                  options={[
                    { label: "All", value: null },
                    { label: "Active", value: "Active" },
                    { label: "Paused", value: "Paused" },
                  ]}
                  classNamePrefix="dropdown"
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              <DataTable
                columns={columns}
                data={totalDocs > 0 ? filteredData : []}
                progressPending={loading}
                progressComponent={<LoadingScreen />}
                paginationTotalRows={totalDocs}
                onChangeRowsPerPage={handleSizePerPageChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
      <ToastContainer
        toastStyle={{ backgroundColor: "#242424", color: "white" }}
      />
    </section>
  );
};

export default Automations;
