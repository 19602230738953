import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";

/* PAGES */
import DashboardLayout from "../layout/index";
import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard/index";
import Users from "../pages/dashboard/users";
import Automations from "../pages/dashboard/automations";
import Support from "../pages/dashboard/support";

/* Toast Notification Import */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>

        {/* DASHBOARD ROUTES */}
        <Routes>
          <Route
            path="/dashboard"
            element={
              <DashboardLayout>
                <Dashboard />
              </DashboardLayout>
            }
          />
          <Route
            path="/users"
            element={
              <DashboardLayout>
                <Users />
              </DashboardLayout>
            }
          />
          <Route
            path="/automations"
            element={
              <DashboardLayout>
                <Automations />
              </DashboardLayout>
            }
          />
          <Route
            path="/support"
            element={
              <DashboardLayout>
                <Support />
              </DashboardLayout>
            }
          />
        </Routes>
        <ToastContainer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
