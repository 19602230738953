/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";

import Select from 'react-select';

/* DATA */
import transactions from "../../data/transactions";

/* Moment import for Date Customization  */
import moment from "moment";

/* API IMPORTS */
import {
  addSubscription,
  getSubscriptions,
  getUsers
} from "../../service/api";
import { SET_FORMAT } from "../../config";

/* Form Control */
import { useFormik } from "formik";
import * as yup from "yup";

/* Toast Notification Import */
import { ToastContainer, toast } from "react-toastify";

/* Validation Schema  */
const validationSchema = yup.object().shape({
  userId: yup.object().required("User ID is Required"),
  name: yup.string(),
  email: yup.string().email().required("Email Address is Required"),
  endDate: yup.string().required("End Date is Required"),
});

const Dashboard = () => {

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [search, setSearch] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [total, setTotal] = useState(0);
  const [transactionModal, setTransactionModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);

  const getSubscriptionData = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
    };
    getSubscriptions(params)
      .then((res) => {
        setFetchedData(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
        let tempTotal = 0
        res?.data?.docs?.map((ele) =>
          tempTotal += ele?.amount & !ele?.isCancelled ? ele?.amount : 0
        )
        setTotal(tempTotal)
      })
      .catch((e) => {
        console.log("e-->", e);
      }).finally(() => {
        setLoading(false);
      });
  };

  const getUserData = () => {

    const params = {
      page: 1,
      sizePerPage: 50,
    };
    if(search){
      params.search = search
    }
    getUsers(params)
      .then((res) => {
        setTotalUsers(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      }).finally(() => {
        setLoading(false);
      });
  };

  const getUserDataSearch = () => {

    const params = {
      page: 1,
      sizePerPage: 100,
    };
    if (formik?.values?.name) {
      params.search = formik?.values?.name
    }
    getUsers(params)
      .then((res) => {
        let tempArray = [];
        res?.data?.docs?.forEach((el) => {
          tempArray.push({ email: el?.email, value: el?._id, label: el?.firstName + " " + el?.lastName })
        })
        setUsersList(tempArray)
      })
      .catch((e) => {
        console.log("e-->", e);
      }).finally(() => {
        setLoading(false);
      });
  };



  const handlePageChange = async (e) => {
    setPage(e)
    getSubscriptionData();
  };
  const handleSizePerPageChange = async (e) => {
    setSizePerPage(e)
    getSubscriptionData();
  };

  function pad(n) {
    return ((n <= 9 && n >= 0) ? "0" : "") + n.toString();
  }

  function timeConverter(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + pad(hour) + ':' + pad(min) + ':' + pad(sec);
    return time;
  }


  function humanReadableToUnixTimestamp(dateString) {
    // Create a Date object from the human-readable string
    const date = new Date(dateString);

    // Check if the date object is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date string provided.");
    }

    // Return the UNIX timestamp (milliseconds since epoch)
    return Math.floor(date.getTime() / 1000);
  }

  /* FORM VALUES HANDLING */
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      endDate: "",
      userId: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      const obj = {
        userId: values?.userId?.value,
        email: values?.email,
        startDate: humanReadableToUnixTimestamp(new Date()),
        endDate: humanReadableToUnixTimestamp(new Date(values?.endDate))
      }
      console.log(obj)
      setFormLoading(true);
      addSubscription(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            getUserData();
            getSubscriptionData();
            formik.resetForm();
            setUsersList([])
            setTransactionModal(!transactionModal)
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setFormLoading(false);
        });
    },
  });

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return <div className="pagination-loading-screen">
      <p>Please wait!</p>
      <p>We are fetching data </p>
    </div>
  }


  useEffect(() => {
    getSubscriptionData();
    getUserData();
  }, [page, sizePerPage, search]);

  useEffect(() => {
    if (formik?.values?.name) {
      getUserDataSearch();
    }
  }, [formik?.values?.name])

  useEffect(() => {
    if (formik.values.userId) {
      formik.setFieldValue("email", formik.values.userId?.email)
    }
  }, [formik.values.userId])

  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => <span className="table-row">{index + 1}</span>,
    },
    {
      name: "Subscribed by",
      grow: 2,
      selector: (row) => (
        <span className="table-row">{row?.user?.firstName + " " + row?.user?.lastName}</span>
      ),
    },
    {
      name: "Date",
      selector: (row) => <span className="table-row">{timeConverter(row.paymentStartDate)}</span>,
    },
    {
      name: "Amount",
      selector: (row) => <span className="table-row">{row?.amount & !row?.isCancelled ? row?.amount : 0}</span>,
    },
    {
      name: "Status",
      selector: (row) => <span className={`table-row ${row.isActive ? "isActive" : "isInactive"}`}>{row.isActive ? "Active" : "In Active"}</span>,
    },
  ];


  return (
    <section className="dashboard">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>ScaleTrade Admin Dashboard</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your company's active users and recent
                transactions of users.
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-flex resp-start">
                <Button className="import-btn" onClick={() => setTransactionModal(!transactionModal)}>
                  Add Manual Subscription
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        {/* ANALYTICS CARD */}
        <Row>
          <Col lg="4" sm="12">
            <Card className="analytics-card">
              <Card.Body className="analytics-card-body">
                <h5>Total Subscriptions</h5>
                <h3>{totalDocs}</h3>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="4" sm="12">
            <Card className="analytics-card">
              <Card.Body className="analytics-card-body">
                <h5>Total Users</h5>
                <h3>{totalUsers}</h3>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col lg="3" sm="12">
            <Card className="analytics-card">
              <Card.Body className="analytics-card-body">
                <h5>Total Bots</h5>
                <h3>2</h3>
              </Card.Body>
            </Card>
          </Col> */}
          <Col lg="4" sm="12">
            <Card className="analytics-card">
              <Card.Body className="analytics-card-body">
                <h5>Total Revenue</h5>
                <h3>{SET_FORMAT(total)} <span>USD</span></h3>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* TRANSACTIONS TABLE */}
        <Row className="mt-4">
          <Col lg="12" md="12" sm="12">
            <Card className="transactions-card">
              <Card.Header className="transactions-card-header">
                <h1>Transactions History</h1>
                <input type="text" onChange={(e)=>setSearch(e?.target?.value)} value={search} placeholder="Search by name" className="form-control" />
              </Card.Header>
              <Card.Body className="transactions-card-body">
                <DataTable
                  className="transactions-table"
                  columns={columns}
                  data={totalDocs > 0 ? fetchedData : []}
                  progressPending={loading}
                  progressComponent={<LoadingScreen />}
                  pagination
                  paginationServer
                  paginationTotalRows={totalDocs}
                  onChangeRowsPerPage={handleSizePerPageChange}
                  onChangePage={handlePageChange}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* ADD MANUAL TRANSACTION */}
        <Modal
          centered
          backdrop="static"
          show={transactionModal}
          onHide={() => setTransactionModal(!transactionModal)}
        >
          <Modal.Header className="delete-user-modal-header">
            <h5>Add Manual Subscription </h5>
          </Modal.Header>
          <Modal.Body className="delete-user-modal-body">
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col sm="12" >
                  <p>
                    <strong>Name: </strong>
                    <Select

                      onInputChange={(inputValue) => {
                        // Here, you can perform additional actions based on the search input
                        formik.setFieldValue("name", inputValue)
                      }}
                      onChange={(e) => { formik.setFieldValue("userId", e) }}
                      options={usersList}
                    />
                    {formik.errors.userId &&
                      formik.touched.userId && (
                        <small style={{ color: "red" }}>
                          {formik.errors.userId}
                        </small>
                      )}
                  </p>
                </Col>
                <Col sm="12">


                </Col>
                <Col sm="12" md="6">
                  <p>
                    <strong>Email: </strong>
                    <input
                      className="form-control"
                      name="email"
                      id="email"
                      type="email"
                      disabled
                      onChange={formik.handleChange}
                      value={formik?.values?.email}
                      defaultValue={formik?.values?.email}
                    />
                    {formik.errors.email &&
                      formik.touched.email && (
                        <small style={{ color: "red" }}>
                          {formik.errors.email}
                        </small>
                      )}
                  </p>
                </Col>
                <Col sm="12" md="6">
                  <p>
                    <strong>End Date: </strong>
                    <input
                      className="form-control"
                      name="endDate"
                      id="endDate"
                      type="date"
                      onChange={formik.handleChange}
                      value={formik?.values?.endDate}
                      defaultValue={formik?.values?.endDate}
                    />
                    {formik.errors.endDate &&
                      formik.touched.endDate && (
                        <small style={{ color: "red" }}>
                          {formik.errors.endDate}
                        </small>
                      )}
                  </p>
                </Col>

              </Row>
              <Button className="view-attachment-btn" type="submit">
                Add Subscription
              </Button>
            </form>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer d-flex" >
            <Button
              className="cancel-btn"
              onClick={() => setTransactionModal(!transactionModal)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
};

export default Dashboard;