/* eslint-disable no-unused-vars */
import cryptoJS from "crypto-js";
import axios from "axios";
import request from "./request";
import scaleTradeRequest from "./requestAPI";
import { encode } from "js-base64";
import {
  environment,
  EXTERNAL_SERVER_URL,
  key,
  encryptionKey,
} from "../config";

const UserWrite =
  environment === "DEV"
    ? process.env.ADMIN
      ? process.env.ADMIN
      : key
    : process.env.USER_WRITE;
    const StrategyRead =
    environment === "DEV"
      ? process.env.ADMIN
        ? process.env.ADMIN
        : key
      : process.env.STRATEGY_READ;

const generateToken = async (data) => {
  return new Promise(async (resolve, reject) => {
    const saltTest = cryptoJS.lib.WordArray.random(16);

    var encrypted = cryptoJS.AES.encrypt(
      data,
      encryptionKey + saltTest.toString()
    );

    encrypted = encrypted.toString();

    resolve(encrypted + "$" + saltTest.toString());
  });
};

// AUTH API
// Login
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("admin/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Reset Password
export const resetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/auth/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Forgot Password
export const forgotPassword = (data) => {
  console.log(data);
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/auth/forgot/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// USERS API
//Get Users List
export const getUsers = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/user`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Get Users List
export const getUsersList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`admin/user/list`,data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Add User
export const addUser = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/user`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit User
export const updateUser = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/user/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete User
export const deleteUser = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/user/` + id)
      .then(async (res) => {
        const updatedToken = await generateToken(
          `key=${UserWrite}&userId=${id}`
        );
        const options = {
          method: "POST",
          url: EXTERNAL_SERVER_URL + "/user/remove/user",
          headers: {
            token: updatedToken,
          },
        };
        await axios
          .request(options)
          .then((response) => {
            console.log("API CALLED", response);
            resolve(res.data);
          })
          .catch((err) => {
            console.log("EXTERNAL SERVER ERROR", err);
            reject(err);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get User Details By Id
export const getEventByID = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/user/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// SUPPORT API
//Get Support List
export const getSupport = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/support`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Add Support
export const addSUpport = (body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/support`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Support
export const editSupport = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/support/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Support
export const deleteSupport = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/support/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Support Details By Id
export const getSupportByID = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/support/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// SUBSCRIPTIONS API
//Get Subscriptions List
export const getSubscriptions = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/subscription`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//Add Manual Subscriptions List
export const addSubscription = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`admin/subscription`, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// GET ALL AUTOMATION LIST
export const getAllAutomationList = (userId) => {
  return new Promise(async (resolve, reject) => {
    const updatedToken = await generateToken(
      `key=${StrategyRead}`
    );
    await scaleTradeRequest
      .get("strategy/get/all", {
        headers: { token: updatedToken },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
