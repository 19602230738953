/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import sampleData from "./sampleData";

/* ICON IMPORTS */
import ImportIcon from "@iconscout/react-unicons/icons/uil-import";
import EditIcon from "@iconscout/react-unicons/icons/uil-edit";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-user-times";
import EyeIcon from "@iconscout/react-unicons/icons/uil-eye";

/* Form Control */
import { useFormik } from "formik";
import * as yup from "yup";

/* Toast Notification Import */
import { ToastContainer, toast } from "react-toastify";


/* Moment import for Date Customization  */
import moment from "moment";

/* API IMPORTS */
import {
  getUsers,
  updateUser,
  addUser,
  deleteUser,
} from "../../../service/api";

/* Validation Schema  */
const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  lastName: yup.string().required("Last Name is Required"),
  mobile: yup.string().required("Phone Number is Required"),
  country: yup.string().required("Country is Required"),
  address: yup.string().required("Address is Required"),
  city: yup.string().required("City is Requried"),
  state: yup.string().required("State is Required"),
  zipCode: yup.string().required("Zip-Code is Required"),
  tradingExp: yup.string().required("Trading Experience is Required"),
  optionTradingExp: yup
    .string()
    .required("Other Trading Experience is Required"),
  hearAboutUs: yup.string().required("Hear About Us is Required"),
  twoFactorAuthentication: yup
    .boolean()
    .required("2 Factor Authentication is Required"),
});

const findUpper = (string1, string2) => {

  function extractFirstLetter(string) {
    let extractedString = [];

    for (var i = 0; i < string?.length; i++) {
      if (
        (string?.charAt(i) === string?.charAt(i).toUpperCase() ||
          string?.charAt(i) === string?.charAt(i).toLowerCase()) &&
        string?.charAt(i) !== " "
      ) {
        extractedString.push(string?.charAt(i).toUpperCase());
      }
    }
    if (extractedString.length > 1) {
      return extractedString[0];
    } else {
      return extractedString[0];
    }
  }
  let letterOne = extractFirstLetter(string1);
  let letterTwo = extractFirstLetter(string2);
  if (letterOne !== undefined && letterTwo !== undefined) {
    return letterOne + letterTwo;
  }
  else if (letterOne === undefined && letterTwo !== undefined) {
    return letterTwo;
  }
  else if (letterOne !== undefined && letterTwo === undefined) {
    return letterOne;
  }
  else {
    return "";
  }

};
const Users = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [search, setSearch] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [viewUser, setViewUser] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const [editUser, setEditUser] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  /* FORM VALUES HANDLING */
  const formikEditForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobile: "",
      country: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      tradingExp: "",
      optionTradingExp: "",
      hearAboutUs: "",
      twoFactorAuthentication: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setEditLoading(true);
      updateUser(editUser?._id, values)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            getUserData();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setEditLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setEditLoading(false);
        });
    },
  });

  useEffect(() => {
    if (editUser !== null) {
      Object.entries(editUser).forEach((entry) => {
        const [key, value] = entry;
        if (validationSchema?.fields?.hasOwnProperty(key)) {
          formikEditForm.setFieldValue(key, value);
        }
      });
    }
  }, [editUser]);

  useEffect(() => {
    console.log("setEditUser", editModal);
    if (!editModal) {
      setEditUser(null);
      formikEditForm.handleReset();
    }
  }, [editModal])


  const handlePageChange = async (e) => {
    setPage(e)
    getUserData();
  };
  const handleSizePerPageChange = async (e) => {
    setSizePerPage(e)
    getUserData();
  };
  const columns = [
    {
      name: "User",
      /* grow: "2", */
      selector: (row) => (
        <div className="user-detail">
          <div className="avatar">
            {findUpper(row?.firstName ? row?.firstName : null, row?.lastName ? row?.lastName : null)}
          </div>
          <h5>
            {row?.firstName} {row?.lastName}
          </h5>
        </div>
      ),
    },
    {
      name: "User ID",
      selector: (row) => <span className="light-text">{row._id}</span>,
    },
    {
      name: "Phone",
      width: "150px",
      selector: (row) => <span className="light-text">{row?.mobile}</span>,
    },
    {
      name: "Email",
      selector: (row) => <span className="light-text">{row.email}</span>,
    },
    {
      name: "Joining Date",
      selector: (row) => (
        <span className="light-text">
          {moment(row.createdAt).format("MMM DD, YYYY HH:MM A")}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <EyeIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setViewUser(row);
              setViewModal(!viewModal);
            }}
          />
          <EditIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setEditUser(row);
              setEditModal(!editModal);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setDeleteId(row?._id);
              setDeleteModal(!deleteModal);
            }}
          />
        </div>
      ),
    },
  ];

  const getUserData = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
    };
    if (search) {
      params.search = search
    }
    getUsers(params)
      .then((res) => {
        setFetchedData(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserData();
  }, [page, sizePerPage, search]);

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteUser(deleteId)
      .then((res) => {
        getUserData();
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
      });
  };

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return <div className="pagination-loading-screen">
      <p>Please wait!</p>
      <p>We are fetching data </p>
    </div>
  }

  return (
    <section className="users">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Users List</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your company's users and edit their information
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-none resp-start">
                <Button className="import-btn">
                  <ImportIcon size="14" color="#323232" className="btn-icon" />
                  Import
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        {/* USERS LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <div className="left-header">
                <h2>Total users on ScaleTrade: {totalDocs}</h2>
              </div>
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                  onChange={(e) => {
                    setSearch(e?.target?.value)
                  }}
                  value={search}
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              <DataTable
                columns={columns}
                data={totalDocs > 0 ? fetchedData : []}
                progressPending={loading}
                progressComponent={<LoadingScreen />}
                pagination
                paginationServer
                paginationTotalRows={totalDocs}
                onChangeRowsPerPage={handleSizePerPageChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </div>

        {/* VIEW MODAL */}
        <Modal
          centered
          backdrop="static"
          show={viewModal}
          onHide={() => setViewModal(!viewModal)}
        >
          <Modal.Header className="delete-user-modal-header">
            <h5>
              User Details For {viewUser?.firstName} {viewUser?.lastName}{" "}
            </h5>
          </Modal.Header>
          <Modal.Body className="delete-user-modal-body">
            <Row>
              <Col sm="12" md="6">
                <p>
                  <strong>Name: </strong>
                  {viewUser?.firstName} {viewUser?.lastName}
                </p>
              </Col>
              <Col sm="12" md="6">
                <p>
                  <strong>Email: </strong>
                  {viewUser?.email}
                </p>
              </Col>
              <Col sm="12" md="6">
                <p>
                  <strong>Phone Number: </strong>
                  {viewUser?.mobile}
                </p>
              </Col>
              <Col sm="12" md="6">
                <p>
                  <strong>Hear About Us: </strong>
                  {viewUser?.hearAboutUs}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12">
                <p>
                  <strong>Address: </strong>
                  <br />
                  {viewUser?.address ? (viewUser?.address + ", " + viewUser?.city + ", " + viewUser?.state + " - " +
                    viewUser?.zipCode + ", " + viewUser?.country) : "No Address Found"}

                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Know about wheel Options: </strong>
                  {viewUser?.knowAboutWheelOptions}
                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Main Goal with ScaleTrade: </strong>
                  {viewUser?.mainGoalWithScaleTrade}
                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Trading Experience: </strong>
                  {viewUser?.tradingExp}
                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Option Trading Experience: </strong>
                  {viewUser?.optionTradingExp}
                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Risk Tolerance: </strong>
                  {viewUser?.riskTolerance}
                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Sectors to Avoid Trading: </strong>
                  {viewUser?.sectorToAvoidTrading}
                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Sectors to Prefer Trading: </strong>
                  {viewUser?.sectorToPreferTrading}
                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Risk Tolerance: </strong>
                  {viewUser?.riskTolerance}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12">
                <p>
                  <strong>Big Market Drops: </strong>
                  {viewUser?.bigMarketDrops}
                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Big Market Spikes: </strong>
                  {viewUser?.bigMarketSpikes}
                </p>
              </Col>
              <Col sm="12" md="7">
                <p>
                  <strong>Timestamp: </strong>
                  <span className="light-text">
                    {moment(viewUser?.createdAt).format("MMM DD, YYYY HH:MM A")}
                  </span>
                </p>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setViewModal(!viewModal)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* EDIT MODAL */}
        <Modal
          centered
          backdrop="static"
          show={editModal}
          onHide={() => setEditModal(!editModal)}
        >
          <Modal.Header className="delete-user-modal-header">
            <h5>
              User Details For {editUser?.firstName} {editUser?.lastName}{" "}
            </h5>
          </Modal.Header>
          <form onSubmit={formikEditForm.handleSubmit} id="edit-form">
            <Modal.Body className="delete-user-modal-body">
              <Row>
                <Col sm="12" md="6">
                  <p>
                    <strong>First Name: </strong>
                    <input
                      className="form-control"
                      name="firstName"
                      id="firstName"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.firstName}
                      defaultValue={formikEditForm?.values?.firstName}
                    />
                    {formikEditForm.errors.firstName &&
                      formikEditForm.touched.firstName && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.firstName}
                        </small>
                      )}
                  </p>
                </Col>
                <Col sm="12" md="6">
                  <p>
                    <strong>Last Name: </strong>
                    <input
                      className="form-control"
                      name="lastName"
                      id="lastName"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.lastName}
                      defaultValue={formikEditForm?.values?.lastName}
                    />
                    {formikEditForm.errors.lastName &&
                      formikEditForm.touched.lastName && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.lastName}
                        </small>
                      )}
                  </p>
                </Col>
                <Col sm="12" md="6">
                  <p>
                    <strong>Phone Number: </strong>
                    <input
                      className="form-control"
                      name="mobile"
                      id="mobile"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.mobile}
                      defaultValue={formikEditForm?.values?.mobile}
                    />
                    {formikEditForm.errors.mobile &&
                      formikEditForm.touched.mobile && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.mobile}
                        </small>
                      )}
                  </p>
                </Col>
                <Col sm="12" md="6">
                  <p>
                    <strong>Hear About Us: </strong>
                    <input
                      className="form-control"
                      name="hearAboutUs"
                      id="hearAboutUs"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.hearAboutUs}
                      defaultValue={formikEditForm?.values?.hearAboutUs}
                    />
                    {formikEditForm.errors.hearAboutUs &&
                      formikEditForm.touched.hearAboutUs && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.hearAboutUs}
                        </small>
                      )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12">
                  <p>
                    <strong>Address: </strong>
                    <input
                      className="form-control"
                      name="address"
                      id="address"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.address}
                      defaultValue={formikEditForm?.values?.address}
                    />
                    {formikEditForm.errors.address &&
                      formikEditForm.touched.address && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.address}
                        </small>
                      )}
                  </p>
                </Col>
                <Col sm="12" md="6">
                  <p>
                    <strong>City: </strong>
                    <input
                      className="form-control"
                      name="city"
                      id="city"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.city}
                      defaultValue={formikEditForm?.values?.city}
                    />
                    {formikEditForm.errors.city &&
                      formikEditForm.touched.city && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.city}
                        </small>
                      )}
                  </p>
                </Col>
                <Col sm="12" md="6">
                  <p>
                    <strong>State: </strong>
                    <input
                      className="form-control"
                      name="state"
                      id="state"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.state}
                      defaultValue={formikEditForm?.values?.state}
                    />
                    {formikEditForm.errors.state &&
                      formikEditForm.touched.state && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.state}
                        </small>
                      )}
                  </p>
                </Col>

                <Col sm="12" md="6">
                  <p>
                    <strong>Country: </strong>
                    <input
                      className="form-control"
                      name="country"
                      id="country"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.country}
                      defaultValue={formikEditForm?.values?.country}
                    />
                    {formikEditForm.errors.country &&
                      formikEditForm.touched.country && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.country}
                        </small>
                      )}
                  </p>
                </Col>
                <Col sm="12" md="6">
                  <p>
                    <strong>Zip-Code: </strong>
                    <input
                      className="form-control"
                      name="zipCode"
                      id="zipCode"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.zipCode}
                      defaultValue={formikEditForm?.values?.zipCode}
                    />
                    {formikEditForm.errors.zipCode &&
                      formikEditForm.touched.zipCode && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.zipCode}
                        </small>
                      )}
                  </p>
                </Col>

                <Col sm="12" md="12">
                  <p>
                    <strong>Trading Experience: </strong>
                    <input
                      className="form-control"
                      name="tradingExp"
                      id="tradingExp"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.tradingExp}
                      defaultValue={formikEditForm?.values?.tradingExp}
                    />
                    {formikEditForm.errors.tradingExp &&
                      formikEditForm.touched.tradingExp && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.tradingExp}
                        </small>
                      )}
                  </p>
                </Col>
                <Col sm="12" md="12">
                  <p>
                    <strong>Option Trading Experience: </strong>
                    <input
                      className="form-control"
                      name="optionTradingExp"
                      id="optionTradingExp"
                      onChange={formikEditForm.handleChange}
                      value={formikEditForm?.values?.optionTradingExp}
                      defaultValue={formikEditForm?.values?.optionTradingExp}
                    />
                    {formikEditForm.errors.optionTradingExp &&
                      formikEditForm.touched.optionTradingExp && (
                        <small style={{ color: "red" }}>
                          {formikEditForm.errors.optionTradingExp}
                        </small>
                      )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12">
                  <p>
                    <strong>Updated At Timestamp: </strong>
                    <span className="light-text">
                      {moment(viewUser?.updatedAt).format(
                        "MMM DD, YYYY HH:MM A"
                      )}
                    </span>
                  </p>
                </Col>
                <Col sm="12" md="12">
                  <p>
                    <strong>Created At Timestamp: </strong>
                    <span className="light-text">
                      {moment(viewUser?.createdAt).format(
                        "MMM DD, YYYY HH:MM A"
                      )}
                    </span>
                  </p>
                </Col>
                {console.log("formikEditForm.errors", formikEditForm.errors)}
              </Row>
            </Modal.Body>
            <Modal.Footer className="add-user-modal-footer">
              <Button
                className="cancel-btn"
                onClick={() => setEditModal(!editModal)}
              >
                Cancel
              </Button>
              {editLoading ? (
                <Button className="proceed-btn" type="button">
                  Please Wait...
                </Button>
              ) : (
                <Button className="proceed-btn" type="submit">
                  Edit
                </Button>
              )}
            </Modal.Footer>
          </form>
        </Modal>

        {/* DELETE MODAL */}
        <Modal
          centered
          backdrop="static"
          show={deleteModal}
          onHide={() => setDeleteModal(!deleteModal)}
        >
          <Modal.Header className="delete-user-modal-header">
            <h5>Confirm Delete</h5>
          </Modal.Header>
          <Modal.Body className="delete-user-modal-body">
            <p>Are you sure you want to delete these form details ?</p>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setDeleteModal(!deleteModal)}
            >
              Cancel
            </Button>
            <Button className="proceed-btn" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer
        toastStyle={{ backgroundColor: "#242424", color: "white" }}
      />
    </section>
  );
};

export default Users;
