/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Modal, Nav, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";

/* ICON IMPORTS */
import ImportIcon from "@iconscout/react-unicons/icons/uil-import";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-trash";
import EyeIcon from "@iconscout/react-unicons/icons/uil-eye";
import Checked from "@iconscout/react-unicons/icons/uil-comment-add";
import NotChecked from "@iconscout/react-unicons/icons/uil-comment-alt";

/* Form Control */
import * as yup from "yup";

/* Toast Notification Import */
import { ToastContainer, toast } from "react-toastify";


/* Moment import for Date Customization  */
import moment from "moment";

/* API IMPORTS */
import {
  getSupport,
  deleteUser,
  editSupport,
  deleteSupport
} from "../../../service/api";
import { async } from "q";

const findUpper = (string1, string2) => {

  function extractFirstLetter(string) {
    let extractedString = [];

    for (var i = 0; i < string?.length; i++) {
      if (
        (string?.charAt(i) === string?.charAt(i).toUpperCase() ||
          string?.charAt(i) === string?.charAt(i).toLowerCase()) &&
        string?.charAt(i) !== " "
      ) {
        extractedString.push(string?.charAt(i).toUpperCase());
      }
    }
    if (extractedString.length > 1) {
      return extractedString[0];
    } else {
      return extractedString[0];
    }
  }
  let letterOne = extractFirstLetter(string1);
  let letterTwo = extractFirstLetter(string2);
  if (letterOne !== undefined && letterTwo !== undefined) {
    return letterOne + letterTwo;
  }
  else if (letterOne === undefined && letterTwo !== undefined) {
    return letterTwo;
  }
  else if (letterOne !== undefined && letterTwo === undefined) {
    return letterOne;
  }
  else {
    return "";
  }

};
const Support = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [search, setSearch] = useState(null);
  const [status, setStatus] = useState('active');
  const [fetchedData, setFetchedData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewSupport, setViewSupport] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const handlePageChange = async (e) => {
    setPage(e)
    getUserData();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e)
    getUserData();
  };

  const handleChangeStatus = async (rowId, status, priority) => {
    const params = {
      status,
      priority
    }
    editSupport(rowId, params)
      .then((res) => {
        getUserData();
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
  }

  const columns = [
    {
      name: "Sr. No",
      width: '70px',
      selector: (row, index) => <span className="light-text">{index + 1}</span>,
    },
    {
      name: "User",
      grow: "2",
      selector: (row) => (
        <div className="user-detail">
          <div className="avatar">
            {findUpper(row?.user?.firstName ? row?.user?.firstName : null, row?.user?.lastName ? row?.user?.lastName : null)}
          </div>
          <h5>
            {row?.user?.firstName} {row?.user?.lastName}
          </h5>
        </div>
      ),
    },
    {
      name: "Description",
      width: '300px',
      grow: "2",
      selector: (row) => <span className="light-text">{row.desc}</span>,
    },
    {
      name: "Ticket No.",
      width: '130px',
      selector: (row) => <span className="light-text">{row?.ticketNumber ? row?.ticketNumber : "-"}</span>,
    },
    {
      name: "Reason",
      width: '200px',
      selector: (row) => <span className="light-text">{row.reason}</span>,
    },
    {
      name: "Subject",
      selector: (row) => <span className="light-text">{row.subject}</span>,
    },
    {
      name: "Priority",
      selector: (row) => <span className={`light-text ${row.priority === "High" ? "high" : "normal"}`}>{row.priority}</span>,
    },
    {
      name: "Timestamp",
      selector: (row) => (
        <span className="light-text">
          {moment(row.createdAt).format("MMM DD, YYYY HH:MM A")}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <EyeIcon
            color="#8094AE"
            size="20"
            className="support-btn"
            onClick={() => {
              setViewSupport(row);
              setViewModal(!viewModal);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            className="support-btn"
            onClick={() => {
              setDeleteId(row?._id);
              setDeleteModal(!deleteModal);
            }}
          />
          {row?.status === "active" ? <Checked
            color="#8094AE"
            size="40"
            className="support-btn"
            onClick={() => {
              handleChangeStatus(row?._id, "resolved", row?.priority)
            }}
          /> : <NotChecked
            color="#8094AE"
            size="40"
            className="support-btn"
            onClick={() => {
              handleChangeStatus(row?._id, "active", row?.priority)
            }}
          />}



        </div>
      ),
    },
  ];

  const getUserData = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
    };
    if (status) {
      params.status = status
    }
    getSupport(params)
      .then((res) => {
        setFetchedData(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserData();
  }, [page, sizePerPage, search, status]);

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteSupport(deleteId)
      .then((res) => {
        getUserData();
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
      });
  };

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return <div className="pagination-loading-screen">
      <p>Please wait!</p>
      <p>We are fetching data </p>
    </div>
  }

  return (
    <section className="users">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="8" sm="12">
              <h1>Support List</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your company's users and edit their information
              </p>
            </Col>
            <Col lg="4" sm="12">
              <div className="d-none resp-start">
                <Button className="import-btn">
                  <ImportIcon size="14" color="#323232" className="btn-icon" />
                  Import
                </Button>
              </div>
            </Col>
          </Row>
        </div>

        <Tab.Container defaultActiveKey="active">
          <Row>
            <Col xl="12" sm="12">
              <div className="users-table">
                <Card className="users-list">
                  <Card.Header className="users-list-header">
                    <Nav
                      variant="pills"
                      className="flex-row justify-content-between"
                    >
                      <div className="d-flex align-items-center">
                        <Nav.Item>
                          <Nav.Link onClick={() => { setStatus('active') }} eventKey="active">Active requests</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link onClick={() => { setStatus('resolved') }} eventKey="resolved">
                            Resolved requests
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    </Nav>
                  </Card.Header>
                  <Card.Body className="users-list-body">
                    <Tab.Content>
                      <DataTable
                        columns={columns}
                        data={totalDocs > 0 ? fetchedData : []}
                        progressPending={loading}
                        progressComponent={<LoadingScreen />}
                        pagination
                        paginationServer
                        paginationTotalRows={totalDocs}
                        onChangeRowsPerPage={handlePerPageChange}
                        onChangePage={handlePageChange}
                      />
                    </Tab.Content>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Tab.Container>

        {/* USERS LIST */}
        {/* <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <div className="left-header">
                <h2>Total support tickets for ScaleTrade: {totalDocs}</h2>
              </div>
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              <DataTable
                columns={columns}
                data={totalDocs > 0 ? fetchedData : []}
                progressPending={loading}
                progressComponent={<LoadingScreen />}
                pagination
                paginationServer
                paginationTotalRows={totalDocs}
                onChangeRowsPerPage={handlePageChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </div> */}

        {/* VIEW MODAL */}
        <Modal
          centered
          backdrop="static"
          show={viewModal}
          onHide={() => setViewModal(!viewModal)}
        >
          <Modal.Header className="delete-user-modal-header">
            <h5>Support For {viewSupport?.subject} </h5>
          </Modal.Header>
          <Modal.Body className="delete-user-modal-body">
            <Row>
              <Col sm="12" md="6">
                <p>
                  <strong>Name: </strong>
                  {viewSupport?.user?.firstName} {viewSupport?.user?.lastName}
                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Email: </strong>
                  {viewSupport?.user?.email}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12">
                <p className="">
                  <strong>Description: </strong>
                  {viewSupport?.desc}
                </p>
              </Col>
              <Col sm="12" md="12">
                <p>
                  <strong>Reason: </strong>
                  {viewSupport?.reason}
                </p>
              </Col>
              <Col>
                <p><strong>Ticket Number: </strong>{viewSupport?.ticketNumber ? viewSupport?.ticketNumber : "-"}</p></Col>
            </Row>
            <Row>
              <Col sm="12">
                <p>
                  <strong>Priority: </strong>
                  {viewSupport?.priority}
                </p>
              </Col>
              <Col sm="12" >
                <p>
                  <strong>Timestamp: </strong>
                  <span className="light-text">
                    {moment(viewSupport?.createdAt).format(
                      "MMM DD, YYYY HH:MM A"
                    )}
                  </span>
                </p>
              </Col>
            </Row>
            {viewSupport?.attachment ? (
              <Button className="view-attachment-btn" type="button">
                <a href={viewSupport?.attachment} className="text-decoration-none">View Attachment</a>
              </Button>
            ) : null}
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setViewModal(!viewModal)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* DELETE MODAL */}
        <Modal
          centered
          backdrop="static"
          show={deleteModal}
          onHide={() => setDeleteModal(!deleteModal)}
        >
          <Modal.Header className="delete-user-modal-header">
            <h5>Confirm Delete</h5>
          </Modal.Header>
          <Modal.Body className="delete-user-modal-body">
            <p>Are you sure you want to delete these form details ?</p>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setDeleteModal(!deleteModal)}
            >
              Cancel
            </Button>
            <Button className="proceed-btn" onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer
        toastStyle={{ backgroundColor: "#242424", color: "white" }}
      />
    </section>
  );
};

export default Support;
